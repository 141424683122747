import React, { useRef, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Snackbar,
    Alert,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import emailjs from '@emailjs/browser'
import './queryForm.css';

interface QueryFormProps {
    open: boolean;
    onClose: () => void;
}

const QueryForm: React.FC<QueryFormProps> = ({ open, onClose }) => {
    const [formData, setFormData] = useState({
        fullName: '',
        service: '',
        email: '',
        contactNumber: '',
        description: ''
    });
    const form = useRef<HTMLFormElement | null>(null);

    const [showSuccess, setShowSuccess] = useState(false);

    const services = [
        "Manpower Recruitment",
        "Cab Rentals",
        "HouseKeeping",
        "Security",
        "General Order Supplier"
    ];

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        setShowSuccess(true);
        if (form.current) {
            emailjs
                .sendForm(
                    'service_fzgv1in', // From EmailJS dashboard
                    'template_eda51sk', // From EmailJS dashboard
                    form.current,
                    'MUQetv_cM39ZK7tMs', // From EmailJS account
                )
                .then(() => console.log('Email sent!'))
                .catch(() => console.log('Failed to send email.'))
        }

        setTimeout(() => {
            setFormData({
                fullName: '',
                service: '',
                email: '',
                contactNumber: '',
                description: ''
            });
            setShowSuccess(false);
            onClose();
        }, 2000);
    };


    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="sm"
                className='dialog-container'
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        position: 'relative'
                    }
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>Get in Touch with Us</DialogTitle>
                <DialogContent>
                    <Box ref={form} component="form" onSubmit={handleSubmit}>
                        <TextField
                            className="textFieldRoot"
                            fullWidth
                            label="Full Name"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                            margin="normal"
                        />

                        <FormControl fullWidth margin="normal" required>
                            <InputLabel
                                sx={{ paddingBottom: '8px', marginBottom: '8px' }}
                            >
                                Select Service
                            </InputLabel>
                            <Select
                                name="service"
                                value={formData.service}
                                onChange={handleChange}
                                label="Select Service"
                            >
                                {services.map((service) => (
                                    <MenuItem key={service} value={service}>
                                        {service}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            margin="normal"
                            className="textFieldRoot"
                        />

                        <TextField
                            fullWidth
                            label="Contact Number"
                            name="contactNumber"
                            value={formData.contactNumber}
                            onChange={handleChange}
                            required
                            margin="normal"
                            className="textFieldRoot"
                        />

                        <TextField
                            fullWidth
                            label="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            multiline
                            rows={4}
                            margin="normal"
                            className="discriptionFieldRoot"
                        />
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }} >
                            <Button
                                type="submit"
                                className='queryButton'
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>

            <Snackbar
                open={showSuccess}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="success">
                    Thank you for your query! We'll get back to you soon.
                </Alert>
            </Snackbar>
        </>
    );
};

export default QueryForm;