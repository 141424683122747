import React, { useRef, useState } from 'react';
import {
  Card,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import {
  Building2, Crown, Shield, Award, Gem, Star,
  CheckCircle2, Users, Clock, Briefcase,
  TreePine, ClipboardCheck, Settings, Timer, Wind,
  ArrowRight
} from 'lucide-react';
import MainHome from './Services_Images/SecurityMobile1.svg';
import QueryForm from '../QueryForm/queryForm';
import HouseKeppingAbout from './Services_Images/HouseKeppingAbout.png';
import './Servicesall.css';

const Housekeeping = () => {
  const [videoError, setVideoError] = useState(false);
  const [isQueryFormOpen, setIsQueryFormOpen] = useState(false);
  const videoRef = useRef(null);

  const service = {
    icon: <Building2 className="w-16 h-16 text-indigo-400" />,
    title: "Enterprise Cleaning Excellence",
    description: "Industry-leading cleaning solutions for Fortune 500 companies, tech campuses, and corporate offices worldwide.",
    price: "Tailored Enterprise Pricing",
    features: [
      "ISO 9001:2015 Certified Operations",
      "Global Compliance Standards",
      "Enterprise-grade Equipment & Solutions",
      "Dedicated Account Management",
      "24/7 Emergency Response",
      "Custom SLA Frameworks",
      "Sustainability Reporting",
      "Risk Management & Insurance"
    ]
  };

  const industries = [
    {
      icon: <Building2 className="w-12 h-12 text-blue-500" />,
      title: "Corporate Offices",
      description: "From single floors to entire buildings"
    },
    {
      icon: <Briefcase className="w-12 h-12 text-purple-500" />,
      title: "Tech Campuses",
      description: "24/7 maintenance for tech spaces"
    },
    {
      icon: <Users className="w-12 h-12 text-green-500" />,
      title: "Co-working Spaces",
      description: "Flexible cleaning solutions"
    },
    {
      icon: <TreePine className="w-12 h-12 text-emerald-500" />,
      title: "Green Buildings",
      description: "Eco-certified cleaning protocols"
    }
  ];

  const features = [
    {
      icon: <ClipboardCheck className="w-12 h-12 text-indigo-500" />,
      title: "ISO Certified Process",
      description: "Internationally recognized standards"
    },
    {
      icon: <Settings className="w-12 h-12 text-purple-500" />,
      title: "Advanced Equipment",
      description: "Enterprise-grade cleaning systems"
    },
    {
      icon: <Timer className="w-12 h-12 text-blue-500" />,
      title: "Real-time Monitoring",
      description: "Digital tracking & reporting"
    },
    {
      icon: <Wind className="w-12 h-12 text-green-500" />,
      title: "Green Certified",
      description: "Sustainable cleaning solutions"
    }
  ];

  const handleConnect = () => {
    setIsQueryFormOpen(true);
  };

  const isMobile = useMediaQuery('(max-width:500px)');

  const videoSource = isMobile
    ? "/videos/HouseKeepingMobile1.mp4"
    : "/videos/HouseKeeping.mp4";

  // <h1 className="text-5xl font-bold text-white mb-8 leading-tight tracking-tight drop-shadow-lg"> Corporate Housekeeping Excellence<br />Tailored to Your Business Needs </h1> 
  // <p className="text-2xl text-white mb-12 leading-relaxed max-w-3xl font-light drop-shadow-lg"> Delivering world-class cleaning solutions to Fortune 500 companies, global tech campuses, and corporate spaces in India. </p>

  return (
    <div className="flex flex-col min-h-screen bg-[#FAFBFF]">
      {/* Hero Section */}
      <div className="min-h-screen grid">
        <div className="relative col-start-1 row-start-1">
          {!videoError ? (
            <video
              ref={videoRef}
              autoPlay
              muted
              loop
              playsInline
              className="w-full h-screen object-cover"
              onError={(e) => {
                console.error('Video error:', e);
                setVideoError(true);
              }}
            >
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={MainHome}
              alt="Resourcing services"
              className="w-full h-screen object-cover"
            />
          )}
        </div>

        <div className="col-start-1 row-start-1 bg-black/40 h-screen" />

        <div className="col-start-1 row-start-1 z-10 flex items-center">
          <div className="container mx-auto px-8">
            <div className="max-w-4xl">
              <h1 className="heading ml-6 text-4xl font-bold text-[#ffc630] mb-8 leading-tight tracking-tight">
                Corporate Housekeeping Excellence<br />Tailored to Your Business Needs
              </h1>
              <p className="para ml-6 text-white/90 mb-12 leading-relaxed max-w-2xl">
                Delivering executive-class transportation solutions<br /> to corporate clients and business professionals<br /> across India.
              </p>
              <div className="connectHouseKeeping">
                <button
                  onClick={handleConnect}
                  className="connect-button connect-buttonHouseKeeping"
                >
                  Connect with US
                  <ArrowRight className="arrow-icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="py-20 bg-gradient-to-b from-indigo-900 to-purple-900 relative">
        <div className="container mx-auto px-8">
          <div className="grid md:grid-cols-4 gap-8">
            {[
              { number: "1000+", label: "Enterprise Clients", icon: <Building2 width={30} height={30} /> },
              { number: "20M+", label: "Sq Ft Maintained", icon: <Award width={30} height={30} /> },
              { number: "99.9%", label: "SLA Compliance", icon: <Shield width={30} height={30} /> },
              { number: "150+", label: "Cities Worldwide", icon: <Gem width={30} height={30} /> }
            ].map((stat, index) => (
              <div key={index} className="relative group">
                <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-indigo-500/20 rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500 opacity-0 group-hover:opacity-100" />
                <div className="relative p-8 rounded-2xl bg-white/10 backdrop-blur-xl border border-white/10 hover:border-white/20 transition-all duration-300 flex flex-col items-center text-center">
                  <div className="text-amber-400 mb-4">{stat.icon}</div>
                  <div className="text-4xl font-bold text-white mb-2">{stat.number}</div>
                  <div className="text-white/70">{stat.label}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Industries We Serve */}
      <div className="container mx-auto px-8 py-24">
        <div className="text-center mb-20">
          <div className="inline-flex items-center gap-2 mb-4 px-6 py-3 bg-purple-100 rounded-full">
            <Building2 className="w-5 h-5 text-purple-600" />
            <span className="bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold">
              Industries We Serve
            </span>
          </div>
          <h2 className="subHeadings font-bold mb-6 text-slate-900">
            Specialized Solutions
          </h2>
        </div>

        <div className="grid md:grid-cols-4 gap-8 mb-24">
          {industries.map((industry, index) => (
            <div key={index} className="group h-full">
              <div className="relative items-center p-8 rounded-2xl bg-white shadow-xl group-hover:shadow-2xl transition-all duration-500 h-full flex flex-col">
                <div className="mb-6">{industry.icon}</div>
                <h3 className="text-xl font-bold text-slate-900 mb-3">{industry.title}</h3>
                <p className="text-slate-600 flex-grow">{industry.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Service Card */}
        <div className="flex justify-center">
          <Card className="group relative overflow-hidden hover:shadow-2xl transition-all duration-500 border-0 max-w-2xl w-full">
            <div className="absolute inset-0 bg-gradient-to-br from-purple-50 to-indigo-50 opacity-50" />
            <div className="absolute inset-0 bg-gradient-to-br from-purple-500/5 to-indigo-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />

            <CardContent className="relative p-10">
              <div className="flex justify-center mb-10">
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-indigo-500/20 rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500" />
                  <div className="relative p-8 rounded-2xl bg-white shadow-xl group-hover:shadow-2xl transition-all duration-500">
                    {service.icon}
                  </div>
                </div>
              </div>

              <div className="text-center mb-10">
                <h3 className="text-3xl font-bold mb-4 text-slate-900">
                  {service.title}
                </h3>
                <p className="text-purple-600 font-semibold text-xl mb-4">
                  {service.price}
                </p>
                <p className="text-slate-600 leading-relaxed text-lg">
                  {service.description}
                </p>
              </div>

              <div className="grid md:grid-cols-2 gap-4 mb-10">
                {service.features.map((feature, idx) => (
                  <div key={idx} className="flex items-center gap-3 text-slate-700">
                    <CheckCircle2 className="w-5 h-5 text-purple-500 flex-shrink-0" />
                    <span>{feature}</span>
                  </div>
                ))}
              </div>

              <button
                onClick={handleConnect}
                className="w-full bg-gradient-to-r from-purple-500 to-indigo-600 hover:from-purple-600 hover:to-indigo-700 text-white py-4 rounded-xl shadow-lg group-hover:shadow-xl transition-all duration-300">
                Request Enterprise Quote
              </button>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Features Grid - World-Class Standards */}
      <div className="bg-gradient-to-b from-purple-50 to-white py-24">
        <div className="container mx-auto px-8">
          <div className="text-center mb-20">
            <div className="inline-flex items-center gap-2 mb-4 px-6 py-3 bg-purple-100 rounded-full">
              <Crown className="w-5 h-5 text-amber-400" />
              <span className="bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold">
                Enterprise Advantages
              </span>
            </div>
            <h2 className="subHeadings font-bold mb-6 text-slate-900">
              World-Class Standards
            </h2>
          </div>

          <div className="grid md:grid-cols-4 gap-12">
            {features.map((feature, index) => (
              <div key={index} className="group text-center h-full">
                <div className="relative h-full">
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-indigo-500/20 rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500" />
                  <div className="relative flex flex-col items-center text-center p-6 rounded-2xl bg-white shadow-xl group-hover:shadow-2xl transition-all duration-500 h-full">
                    <div className="mb-3">{feature.icon}</div>
                    <h3 className="text-xl font-bold text-slate-900 mb-3">{feature.title}</h3>
                    <p className="text-slate-600">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

        {/* Company Description Section */}
<div className="py-14 px-5 w-full bg-[#0f313e] text-white">
  {/* Centered Title Section */}
  <div className="flex text-center justify-center mb-4">
    <div className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold transform transition-all duration-500 hover:scale-105">
      <h2 className="cardtitle font-bold transform transition-all duration-500 text-white">
        Why choose <span className="text-[#179da3]">SR Housekeeping Service</span> for your <span className="text-[#179da3]">cleaning</span> needs?
      </h2>
    </div>
  </div>
  
  <div className="max-w-7xl mx-auto py-5 px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center gap-6">
    {/* Text Section - 60% width */}
    <div className="md:w-3/5 space-y-3">
      <p className="fontType text-white">
        A clean and organized workspace is essential for employee productivity and client satisfaction.
        SR Resourcing Pvt. Ltd. offers professional housekeeping services to ensure your corporate
        office, industrial site, or commercial establishment remains spotless and well-maintained.
        Our team of expert cleaners and maintenance staff delivers customized cleaning schedules,
        catering to all areas including office spaces, restrooms, break rooms, and common areas.
      </p>
      <h5 className="text-[#179da3] text-xl text-center font-bold mt-7">Our Experience</h5>
      <p className="fontType text-white">
        We have helped numerous organizations maintain immaculate premises. From multinational
        headquarters to small office spaces, we have tailored our housekeeping services to fit
        the specific needs of each business. Our longstanding clients appreciate our reliability,
        attention to detail, and ability to scale services according to their requirements, ensuring
        that their spaces are always welcoming and conducive to productivity.
      </p>
    </div>

    {/* Image Section - 40% width */}
    <div className="md:w-2/5 transform transition-all duration-700 hover:scale-110">
      <div>
        <img
          src={HouseKeppingAbout}
          alt="Housekeeping Services"
          className="aboutImageHousekeeping"
        />
      </div>
    </div>
  </div>
</div>

      {/* Testimonials Section */}
      <div className="bg-gradient-to-b from-purple-50 to-white py-24">
        <div className="container mx-auto px-8 max-w-7xl">
          <div className="text-center mb-20">
            <div className="inline-flex items-center gap-2 mb-4 px-6 py-3 bg-purple-100 rounded-full">
              <Crown className="w-5 h-5 text-amber-400" />
              <span className="bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold">
                Success Stories
              </span>
            </div>
            <h2 className="subHeadings font-bold mb-6 text-slate-900">
              Trusted by Global Leaders
            </h2>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                name: "Saurav Raj",
                role: "Global Facilities Director, Fortune 500 Tech Company",
                quote: "Their enterprise-grade solutions and commitment to excellence have transformed our facility management across 20+ locations worldwide."
              },
              {
                name: "Sarah Khan",
                role: "Head of Corporate Services, International Bank",
                quote: "The level of professionalism and attention to detail is unmatched. Their ISO-certified processes ensure consistent quality across all our offices."
              },
              {
                name: "Dr. Anil kumar",
                role: "CEO, Innovation Hub Network",
                quote: "From smart cleaning solutions to real-time reporting, they've revolutionized how we maintain our innovation spaces across continents."
              }
            ].map((testimonial, index) => (
              <Card key={index} className="group hover:shadow-2xl transition-all duration-500 border-0 bg-gradient-to-br from-slate-50 to-white">
                <CardContent className="p-10">
                  <div className="flex mb-8 justify-center">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star key={star} className="w-6 h-6 text-amber-400 fill-current" />
                    ))}
                  </div>
                  <p className="text-slate-700 mb-8 text-lg leading-relaxed text-center">
                    "{testimonial.quote}"
                  </p>
                  <div className="flex items-center gap-4">
                    <div className="w-16 h-16 rounded-full bg-gradient-to-br from-emerald-200 to-blue-100 shadow-lg" />
                    <div>
                      <p className="font-bold text-slate-900">{testimonial.name}</p>
                      <p className="text-slate-600">{testimonial.role}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <QueryForm open={isQueryFormOpen} onClose={() => setIsQueryFormOpen(false)} />
    </div>
  );
};

export default Housekeeping;