import React from 'react';
import { 
  MapPin, 
  Phone, 
  Mail, 
  Clock,
  Send,
  MessageSquare,
  Building
} from 'lucide-react';
import { 
  Card, 
  CardContent, 
} from '@mui/material';

const ContactPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-indigo-50">
      {/* Hero Section */}
      <div className="relative py-24 bg-gradient-to-b from-indigo-900 to-purple-900">
        <div className="container mx-auto px-8 relative z-10">
          <div className="text-center">
            <h1 className="text-5xl font-bold text-white mb-6">
              Get in Touch
            </h1>
            <p className="text-xl text-white/80 max-w-2xl mx-auto">
              We're here to help and answer any questions you might have
            </p>
          </div>
        </div>
      </div>

      {/* Contact Information Cards */}
      <div className="container mx-auto px-8 -mt-16 relative z-20">
        <div className="grid md:grid-cols-3 gap-8">
          {[
            {
              icon: <Phone className="w-6 h-6 text-indigo-400" />,
              title: "Call Us",
              content: "+1 (555) 123-4567",
              subContent: "Monday to Friday, 9am - 6pm"
            },
            {
              icon: <Mail className="w-6 h-6 text-indigo-400" />,
              title: "Email Us",
              content: "contact@company.com",
              subContent: "We'll respond within 24 hours"
            },
            {
              icon: <MapPin className="w-6 h-6 text-indigo-400" />,
              title: "Visit Us",
              content: "13/347 Indira nagar",
              subContent: "Lucknow, 226016"
            }
          ].map((item, index) => (
            <Card key={index} className="bg-white shadow-lg hover:shadow-xl transition-all duration-300">
              <CardContent className="p-6">
                <div className="rounded-full bg-indigo-50 w-12 h-12 flex items-center justify-center mb-4">
                  {item.icon}
                </div>
                <h3 className="text-xl font-bold text-slate-900 mb-2">{item.title}</h3>
                <p className="text-indigo-600 font-medium mb-1">{item.content}</p>
                <p className="text-slate-500 text-sm">{item.subContent}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      {/* Main Content Section */}
      <div className="container mx-auto px-8 py-24">
        <div className="grid lg:grid-cols-2 gap-16">
          {/* Contact Form */}
          <div>
            <h2 className="text-3xl font-bold text-slate-900 mb-8">Send us a Message</h2>
            <form className="space-y-6">
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-slate-700 mb-2">First Name</label>
                  <input 
                    type="text" 
                    className="w-full px-4 py-3 rounded-lg border border-slate-200 focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 outline-none transition-all"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-slate-700 mb-2">Last Name</label>
                  <input 
                    type="text" 
                    className="w-full px-4 py-3 rounded-lg border border-slate-200 focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 outline-none transition-all"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">Email Address</label>
                <input 
                  type="email" 
                  className="w-full px-4 py-3 rounded-lg border border-slate-200 focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 outline-none transition-all"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">Subject</label>
                <input 
                  type="text" 
                  className="w-full px-4 py-3 rounded-lg border border-slate-200 focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 outline-none transition-all"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">Message</label>
                <textarea 
                  rows={6}
                  className="w-full px-4 py-3 rounded-lg border border-slate-200 focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400 outline-none transition-all resize-none"
                />
              </div>
              <button 
                type="submit"
                className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 text-white font-medium px-8 py-4 rounded-lg hover:opacity-90 transition-all duration-300 flex items-center justify-center space-x-2"
              >
                <span>Send Message</span>
                <Send className="w-4 h-4" />
              </button>
            </form>
          </div>

          {/* Company Information */}
          <div className="lg:pl-16">
            <h2 className="text-3xl font-bold text-slate-900 mb-8">Additional Information</h2>
            <div className="space-y-8">
              <div>
                <h3 className="text-xl font-bold text-slate-900 mb-4 flex items-center">
                  <MessageSquare className="w-5 h-5 mr-2 text-indigo-400" />
                  Customer Support
                </h3>
                <p className="text-slate-600 leading-relaxed">
                  Our dedicated support team is available to assist you with any questions or concerns you may have. We strive to provide exceptional service to all our valued customers.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-bold text-slate-900 mb-4 flex items-center">
                  <Clock className="w-5 h-5 mr-2 text-indigo-400" />
                  Business Hours
                </h3>
                <ul className="space-y-2 text-slate-600">
                  <li>Monday - Friday: 9:00 AM - 6:00 PM</li>
                  <li>Saturday: 10:00 AM - 4:00 PM</li>
                  <li>Sunday: Closed</li>
                </ul>
              </div>
              <div>
                <h3 className="text-xl font-bold text-slate-900 mb-4 flex items-center">
                  <Building className="w-5 h-5 mr-2 text-indigo-400" />
                  Office Location
                </h3>
                <div className="space-y-4">
                  <div>
                    <h4 className="font-medium text-indigo-600">Lucknow</h4>
                    <p className="text-slate-600">13/347 Indira nagar<br />Lucknow, 226016</p>
                  </div>
                  {/* <div>
                    <h4 className="font-medium text-indigo-600">London</h4>
                    <p className="text-slate-600">456 Commerce Street<br />London, UK SW1A 1AA</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;