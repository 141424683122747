import React from 'react';
import { Link } from 'react-router-dom';
import MainHome from '../NavBar/sr_consulting.jpg';
import { Phone, Mail, MapPin, Instagram, Facebook, Twitter, Linkedin } from 'lucide-react';

const Footer = () => {
  const services = [
    { name: "Manpower Recruitment", path: "/manPower" },
    { name: "Cab Rentals", path: "/cabRental" },
    { name: "Housekeeping", path: "/houseKeeping" },
    { name: "Security Services", path: "/security" },
    { name: 'Other services', path: '/others' }
  ];

  const company = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Careers", path: "/careers" },
    { name: "Contact", path: "/ContactUs" }
  ];

  return (
    <>
      {/* Desktop Footer (md and larger) */}
      <footer
        style={{ backgroundColor: '#150e47' }}
        className="text-white py-10 hidden md:block"
      >
        {/* Top Section with Logo and Social Media */}
        <div className="flex justify-between items-center px-28 mb-8">
          {/* Logo Section */}
          <Link to="/" className="flex items-center space-x-3 transform hover:scale-105 transition-transform duration-300">
            <img src={MainHome} alt="SR Resourcing" className="w-8 rounded-2xl" />
            <span className="text-2xl font-bold">
              SR Resourcing Pvt Ltd
            </span>
          </Link>

          {/* Social Media Section */}
          <div className="flex items-center space-x-4">
            <span className="text-lg font-semibold mr-4">Follow Us On</span>
            <div className="flex space-x-4">
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="transform hover:scale-110 transition-transform duration-300">
                <Instagram className="w-6 h-6 text-white hover:text-purple-400 transition-colors duration-300" />
              </a>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="transform hover:scale-110 transition-transform duration-300">
                <Facebook className="w-6 h-6 text-white hover:text-purple-400 transition-colors duration-300" />
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="transform hover:scale-110 transition-transform duration-300">
                <Twitter className="w-6 h-6 text-white hover:text-purple-400 transition-colors duration-300" />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="transform hover:scale-110 transition-transform duration-300">
                <Linkedin className="w-6 h-6 text-white hover:text-purple-400 transition-colors duration-300" />
              </a>
            </div>
          </div>
        </div>

        <div className="serviceContainer mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="container items-center py-6">
            {/* Main Footer Content */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-14 place-items-start px-8">
              {/* Company Info */}
              <div className="space-y-6 w-full">
                <h3 className="text-2xl font-semibold mb-6">Locate Us</h3>
                <div className="space-y-4">
                  <div className="flex items-center gap-3 cursor-pointer">
                    <MapPin className="w-5 h-5 text-purple-400 animate-pulse" />
                    <span className='hover:text-purple-400 transition-colors duration-300'>13/247 Indira nagar<br />
                      Lucknow, 226016<br /> Uttar Pradesh</span>
                  </div>
                  <h3 className="text-2xl font-semibold mb-6 mt-4">Contact Us</h3>
                  <div className="space-y-3">
                    <div className="flex items-center gap-3 cursor-pointer">
                      <Phone className="w-5 h-5 text-purple-400 animate-pulse" />
                      <span className='hover:text-purple-400 transition-colors duration-300'>+91 9336011178</span>
                    </div>
                    <div className="flex items-center gap-3 cursor-pointer">
                      <Phone className="w-5 h-5 text-purple-400 animate-pulse" />
                      <span className='hover:text-purple-400 transition-colors duration-300'>+91 9415119636</span>
                    </div>
                    <div className="flex items-center gap-3 cursor-pointer">
                      <Mail className="w-5 h-5 text-purple-400 animate-pulse" />
                      <span className='hover:text-purple-400 transition-colors duration-300'>info@srresourcing.com</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Services */}
              <div className="w-full">
                <h3 className="text-2xl font-semibold mb-6">Services</h3>
                <ul className="serviceSection text-xl space-y-4">
                  {services.map((service, index) => (
                    <li
                      className='transform hover:scale-105 transition-transform duration-300'
                      key={index}>
                      <Link
                        to={service.path}
                        className="text-white/70 hover:text-purple-400 transition-colors duration-300"
                      >
                        {service.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Company */}
              <div className="w-full">
                <h3 className="text-2xl font-semibold mb-6">Company</h3>
                <ul className="text-xl space-y-4">
                  {company.map((item, index) => (
                    <li
                      className='transform hover:scale-105 transition-transform duration-300'
                      key={index}>
                      <Link
                        to={item.path}
                        className="text-white/70 hover:text-purple-400 transition-colors duration-300"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t border-white/10 my-8" />

        {/* Bottom Footer */}
        <div className="flex flex-row justify-between items-center gap-4 px-10">
          <p className="text-white/70 text-sm">
            &copy; 2024 SR Resourcing. All rights reserved.
          </p>
          <div className="flex gap-6">
            <Link to="/privacy" className="text-white/70 hover:text-white text-sm hover:underline">Privacy Policy</Link>
            <Link to="/terms" className="text-white/70 hover:text-white text-sm hover:underline">Terms of Service</Link>
          </div>
        </div>
      </footer>

      {/* Mobile Footer (smaller than md) */}
      <footer
        style={{ backgroundColor: '#150e47' }}
        className="text-white py-10 md:hidden"
      >
        {/* Top Section with Logo and Social Media */}
        <div className="flex justify-between items-center px-10 mb-8 gap-4 flex-col space-y-4 text-center">
          {/* Logo Section */}
          <Link to="/" className="flex flex-col items-center space-y-2 transform hover:scale-105 transition-transform duration-300">
            <img src={MainHome} alt="SR Resourcing" className="w-8 rounded-lg" />
            <span className="text-2xl font-bold">
              SR Resourcing Pvt Ltd
            </span>
          </Link>

          {/* Social Media Section */}
          <div className="flex flex-col items-center space-y-4">
            <span className="text-lg font-semibold">Follow Us On</span>
            <div className="flex space-x-6">
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="transform hover:scale-110 transition-transform duration-300">
                <Instagram className="w-6 h-6 text-white hover:text-purple-400 transition-colors duration-300" />
              </a>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="transform hover:scale-110 transition-transform duration-300">
                <Facebook className="w-6 h-6 text-white hover:text-purple-400 transition-colors duration-300" />
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="transform hover:scale-110 transition-transform duration-300">
                <Twitter className="w-6 h-6 text-white hover:text-purple-400 transition-colors duration-300" />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="transform hover:scale-110 transition-transform duration-300">
                <Linkedin className="w-6 h-6 text-white hover:text-purple-400 transition-colors duration-300" />
              </a>
            </div>
          </div>
        </div>

        <div className="serviceContainer mx-auto max-w-7xl px-4">
          <div className="container items-center py-6">
            {/* Main Footer Content */}
            <div className="grid grid-cols-1 gap-12 place-items-center">
              {/* Locate Us & Contact Us in a row */}
              <div className="w-full flex justify-around text-center">
                {/* Locate Us */}
                <div>
                  <h3 className="text-2xl font-semibold mb-6">Locate Us</h3>
                  <div className="flex items-center justify-center gap-3 cursor-pointer">
                    <MapPin className="w-5 h-5 text-purple-400 animate-pulse" />
                    <span className='hover:text-purple-400 transition-colors duration-300 text-start'>13/247 Indira nagar<br />
                      Lucknow, 226016<br /> Uttar Pradesh</span>
                  </div>
                </div>

                {/* Contact Us */}
                <div>
                  <h3 className="text-2xl font-semibold mb-6">Contact Us</h3>
                  <div className="space-y-3">
                    <div className="flex items-center justify-center gap-3 cursor-pointer">
                      <Phone className="w-5 h-5 text-purple-400 animate-pulse" />
                      <span className='hover:text-purple-400 transition-colors duration-300 text-start'>+91 9336011178</span>
                    </div>
                    <div className="flex items-center justify-center gap-3 cursor-pointer">
                      <Phone className="w-5 h-5 text-purple-400 animate-pulse" />
                      <span className='hover:text-purple-400 transition-colors duration-300'>+91 9415119636</span>
                    </div>
                    <div className="flex items-center justify-center gap-3 cursor-pointer">
                      <Mail className="w-5 h-5 text-purple-400 animate-pulse" />
                      <span className='hover:text-purple-400 transition-colors duration-300 text-xs'>info@srresourcing.com</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Services & Company (Side by side) */}
              <div className="w-full flex justify-around">
                {/* Services */}
                <div>
                  <h3 className="text-2xl font-semibold mb-6 text-center">Services</h3>
                  <ul className="serviceSection space-y-4 text-center">
                    {services.map((service, index) => (
                      <li
                        className='transform hover:scale-105 transition-transform duration-300 text-base'
                        key={index}>
                        <Link
                          to={service.path}
                          className="text-white/70 hover:text-purple-400 transition-colors duration-300"
                        >
                          {service.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Company */}
                <div>
                  <h3 className="text-2xl font-semibold mb-6 text-center">Company</h3>
                  <ul className="space-y-4 text-center text-base">
                    {company.map((item, index) => (
                      <li
                        className='transform hover:scale-105 transition-transform duration-300'
                        key={index}>
                        <Link
                          to={item.path}
                          className="text-white/70 hover:text-purple-400 transition-colors duration-300"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t border-white/10 my-8" />

        {/* Bottom Footer */}
        <div className="flex flex-col items-center gap-4 px-10 text-center">
          <p className="text-white/70 text-sm">
            &copy; 2024 SR Resourcing. All rights reserved.
          </p>
          <div className="flex flex-col items-center gap-3">
            <Link to="/privacy" className="text-white/70 hover:text-white text-sm hover:underline">Privacy Policy</Link>
            <Link to="/terms" className="text-white/70 hover:text-white text-sm hover:underline">Terms of Service</Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;