import React, { useRef, useState } from 'react';
import {
  Card,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import {
  Car, Crown, Shield, Award, Gem, Star,
  ArrowRight, CheckCircle2, Users, Briefcase,
  MapPin, Calendar, Settings, Timer, Building2
} from 'lucide-react';
import MainHome from './Services_Images/GeneralOrderAbout.png';
import QueryForm from '../QueryForm/queryForm';

const CabRental = () => {
  const [videoError, setVideoError] = useState(false);
  const [isQueryFormOpen, setIsQueryFormOpen] = useState(false);
  const videoRef = useRef(null);

  const service = {
    icon: <Car className="w-16 h-16 text-indigo-400" />,
    title: "Enterprise Transportation Excellence",
    description: "Premium transportation solutions for corporate clients, executives, and business professionals worldwide.",
    price: "Tailored Enterprise Pricing",
    features: [
      "ISO 9001:2015 Certified Operations",
      "Global Fleet Management",
      "Premium Vehicle Selection",
      "Dedicated Account Management",
      "24/7 Concierge Service",
      "Custom SLA Frameworks",
      "Real-time Tracking & Reporting",
      "Comprehensive Insurance Coverage"
    ]
  };

  const industries = [
    {
      icon: <Building2 className="w-12 h-12 text-blue-500" />,
      title: "Corporate Travel",
      description: "Executive transportation solutions"
    },
    {
      icon: <Briefcase className="w-12 h-12 text-purple-500" />,
      title: "Airport Transfers",
      description: "Premium airport pickup & drop"
    },
    {
      icon: <Users className="w-12 h-12 text-green-500" />,
      title: "Event Transport",
      description: "Corporate event logistics"
    },
    {
      icon: <Calendar className="w-12 h-12 text-emerald-500" />,
      title: "Long-term Rental",
      description: "Extended corporate leasing"
    }
  ];

  const features = [
    {
      icon: <Shield className="w-12 h-12 text-indigo-500" />,
      title: "Safety First",
      description: "Certified drivers & vehicles"
    },
    {
      icon: <Settings className="w-12 h-12 text-purple-500" />,
      title: "Premium Fleet",
      description: "Luxury vehicles available"
    },
    {
      icon: <Timer className="w-12 h-12 text-blue-500" />,
      title: "24/7 Support",
      description: "Round-the-clock assistance"
    },
    {
      icon: <MapPin className="w-12 h-12 text-green-500" />,
      title: "Global Coverage",
      description: "Service across major cities"
    }
  ];

  // Use MUI's useMediaQuery to detect screen width
  const isMobile = useMediaQuery('(max-width:500px)');

  const videoSource = isMobile
    ? "/videos/CabRentalMobile.mp4"
    : "/videos/S.RCabRental.mp4";

  const handleConnect = () => {
    setIsQueryFormOpen(true);
  };

  return (
    <div className="flex flex-col min-h-screen  bg-[#FAFBFF]">
      {/* Hero Section */}

      <div className="min-h-screen grid">
        <div className="relative col-start-1 row-start-1">
          {!videoError ? (
            <video
              ref={videoRef}
              autoPlay
              muted
              loop
              playsInline
              className="w-full h-screen object-cover"
              onError={(e) => {
                console.error('Video error:', e);
                setVideoError(true);
              }}
            >
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={MainHome}
              alt="Resourcing services"
              className="w-full h-screen object-cover"
              style={{ opacity: '0.8' }}
            />
          )}
        </div>

        <div className="col-start-1 row-start-1 bg-black/40 h-screen" />

        <div className="col-start-1 row-start-1 z-10 flex items-center">
          <div className="container mx-auto px-8">
            <div className="max-w-4xl">
              <h1 className="headingCabReantal ml-28 text-3xl font-bold text-[#ffc630] mb-8 leading-tight tracking-tight">
                Corporate Transportation<br />Tailored to Your Business
              </h1>
              <p className="paraCabReantal ml-6 text-white/90 mb-12 leading-relaxed max-w-2xl">
                Delivering executive-class transportation solutions to corporate clients and business professionals across India.
              </p>
              <div className="absolute left-0 right-0 bottom-20 p-12">
                <button
                  onClick={handleConnect}
                  className="connect-button connect-buttonCabRental"
                >
                  Connect with US
                  <ArrowRight className="arrow-icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-black/10" />

      {/* Stats Section */}
      <div className="py-20 bg-gradient-to-b from-indigo-900 to-purple-900 relative">
        <div className="container mx-auto px-8">
          <div className="grid md:grid-cols-4 gap-8">
            {[
              { number: "500+", label: "Corporate Clients", icon: <Building2 width={30} height={30} /> },
              { number: "1M+", label: "Rides Completed", icon: <Award width={30} height={30} /> },
              { number: "99.9%", label: "On-Time Arrival", icon: <Shield width={30} height={30} /> },
              { number: "50+", label: "Cities Covered", icon: <Gem width={30} height={30} /> }
            ].map((stat, index) => (
              <div key={index} className="relative group">
                <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-indigo-500/20 rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500 opacity-0 group-hover:opacity-100" />
                <div className="relative p-8 rounded-2xl bg-white/10 backdrop-blur-xl border border-white/10 hover:border-white/20 transition-all duration-300 flex flex-col items-center text-center">
                  <div className="text-amber-400 mb-4">{stat.icon}</div>
                  <div className="text-4xl font-bold text-white mb-2">{stat.number}</div>
                  <div className="text-white/70">{stat.label}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Industries We Serve */}
      <div className="container mx-auto px-8 py-24">
        <div className="text-center mb-20">
          <div className="inline-flex items-center gap-2 mb-4 px-6 py-3 bg-purple-100 rounded-full">
            <Car className="w-5 h-5 text-purple-600" />
            <span className="bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold">
              Services We Offer
            </span>
          </div>
          <h2 className="subHeadings font-bold mb-6 text-slate-900">
            Premium Transportation
          </h2>
        </div>

        <div className="grid md:grid-cols-4 gap-8 mb-24">
          {industries.map((industry, index) => (
            <div key={index} className="group h-full">
              <div className="relative items-center p-8 rounded-2xl bg-white shadow-xl group-hover:shadow-2xl transition-all duration-500 h-full flex flex-col">
                <div className="mb-6 ">{industry.icon}</div>
                <h3 className="text-xl font-bold text-slate-900 mb-3">{industry.title}</h3>
                <p className="text-slate-600 flex-grow">{industry.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Service Card */}
        <div className="flex justify-center">
          <Card className="group relative overflow-hidden hover:shadow-2xl transition-all duration-500 border-0 max-w-2xl w-full">
            <div className="absolute inset-0 bg-gradient-to-br from-purple-50 to-indigo-50 opacity-50" />
            <div className="absolute inset-0 bg-gradient-to-br from-purple-500/5 to-indigo-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />

            <CardContent className="relative p-10">
              <div className="flex justify-center mb-10">
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-indigo-500/20 rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500" />
                  <div className="relative p-8 rounded-2xl bg-white shadow-xl group-hover:shadow-2xl transition-all duration-500">
                    {service.icon}
                  </div>
                </div>
              </div>

              <div className="text-center mb-10">
                <h3 className="text-3xl font-bold mb-4 text-slate-900">
                  {service.title}
                </h3>
                <p className="text-purple-600 font-semibold text-xl mb-4">
                  {service.price}
                </p>
                <p className="text-slate-600 leading-relaxed text-lg">
                  {service.description}
                </p>
              </div>

              <div className="grid md:grid-cols-2 gap-4 mb-10">
                {service.features.map((feature, idx) => (
                  <div key={idx} className="flex items-center gap-3 text-slate-700">
                    <CheckCircle2 className="w-5 h-5 text-purple-500 flex-shrink-0" />
                    <span>{feature}</span>
                  </div>
                ))}
              </div>

              <button
                onClick={handleConnect}
                className="w-full bg-gradient-to-r from-purple-500 to-indigo-600 hover:from-purple-600 hover:to-indigo-700 text-white py-4 rounded-xl shadow-lg group-hover:shadow-xl transition-all duration-300">
                Request Corporate Account
              </button>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Features Grid */}
      <div className="bg-gradient-to-b from-purple-50 to-white py-24">
        <div className="container mx-auto px-8">
          <div className="text-center mb-20">
            <div className="inline-flex items-center gap-2 mb-4 px-6 py-3 bg-purple-100 rounded-full">
              <Crown className="w-5 h-5 text-amber-400" />
              <span className="bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold">
                SR Features
              </span>
            </div>
            <h2 className="subHeadings font-bold mb-6 text-slate-900">
              Elevating Your Travel Experience
            </h2>
          </div>

          <div className="grid md:grid-cols-4 gap-12">
            {features.map((feature, index) => (
              <div key={index} className="group text-center h-full">
                <div className="relative h-full">
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-indigo-500/20 rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500" />
                  <div className="relative flex flex-col items-center text-center p-6 rounded-2xl bg-white shadow-xl group-hover:shadow-2xl transition-all duration-500 h-full">
                    <div className="mb-3">{feature.icon}</div>
                    <h3 className="text-xl font-bold text-slate-900 mb-3">{feature.title}</h3>
                    <p className="text-slate-600">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Company Description Section */}
      <div className="py-14 px-5 w-full bg-[#0f313e] text-white">
        {/* Centered Title Section */}
        <div className="flex text-center justify-center mb-4">
          <div className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold transform transition-all duration-500 hover:scale-105">
            <h2 className="cardtitle font-bold transform transition-all duration-500 text-white">
            Why choose <span className="text-[#179da3]">SR Cab Rental</span> Service for your travel needs <span className="text-[#179da3]">?</span> 
            </h2>
          </div>
        </div>

        <div className="max-w-7xl mx-auto py-5 px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center gap-12">
          <div className="flex-1 space-y-4">
            <p className="fontType text-white ">
            For over 25 years, SR Resourcing Pvt. Ltd. has been a trusted partner for corporate 
            clients in need of reliable, efficient, and comfortable transportation solutions. 
            We provide a diverse fleet of well-maintained vehicles, including luxury cars, vans,
             and buses, with experienced drivers trained to meet the unique needs of businesses. 
             Whether it’s for daily office commutes, business meetings, corporate events, or airport
              transfers, we offer timely and secure transportation, ensuring your employees and executives
               travel with ease.
            </p>
            <h5 className="text-[#179da3] text-center text-2xl font-bold mt-7">Our Experience</h5>
            <p className="fontType text-white">
            With decades of experience in the corporate transport sector, 
            we have developed an in-depth understanding of the logistical challenges businesses face. 
            Our proven track record includes working with large multinational corporations, offering 
            seamless travel solutions that enhance operational efficiency and employee satisfaction. 
            We specialize in scaling services to meet the demands of any corporate environment, 
            from small teams to large workforces.
            </p>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="bg-gradient-to-b from-purple-50 to-white py-24">
        <div className="container mx-auto px-8 max-w-7xl">
          <div className="text-center mb-20">
            <div className="inline-flex items-center gap-2 mb-4 px-6 py-3 bg-purple-100 rounded-full">
              <Crown className="w-5 h-5 text-amber-400" />
              <span className="bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold">
                Client Testimonials
              </span>
            </div>
            <h2 className="subHeadings font-bold mb-6 text-slate-900">
              What Our Clients Say
            </h2>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                name: "Rajesh Kumar",
                role: "Travel Manager, Global Tech Company",
                quote: "Their executive fleet and professional chauffeurs have consistently exceeded our expectations for corporate travel needs."
              },
              {
                name: "Priya Sharma",
                role: "Director of Operations, Multinational Bank",
                quote: "The reliability and luxury of their service make them our go-to choice for executive transportation across all our locations."
              },
              {
                name: "Mike Chen",
                role: "CEO, International Consulting Firm",
                quote: "Their attention to detail and premium service quality has made corporate travel a seamless experience for our entire team."
              }
            ].map((testimonial, index) => (
              <Card key={index} className="group hover:shadow-2xl transition-all duration-500 border-0 bg-gradient-to-br from-slate-50 to-white">
                <CardContent className="p-10">
                  <div className="flex mb-8 justify-center">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star key={star} className="w-6 h-6 text-amber-400 fill-current" />
                    ))}
                  </div>
                  <p className="text-slate-700 mb-8 text-lg leading-relaxed text-center">
                    "{testimonial.quote}"
                  </p>
                  <div className="flex items-center gap-4">
                    <div className="w-16 h-16 rounded-full bg-gradient-to-br from-emerald-200 to-blue-100 shadow-lg" />
                    <div>
                      <p className="font-bold text-slate-900">{testimonial.name}</p>
                      <p className="text-slate-600">{testimonial.role}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>

      <QueryForm open={isQueryFormOpen} onClose={() => setIsQueryFormOpen(false)} />
    </div>
  );
};

export default CabRental;