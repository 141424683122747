import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import {
  Users, Crown, Shield, Award, Gem, Star,
  ArrowRight, CheckCircle2, Briefcase,
  MapPin, Calendar, Settings, Timer, Building2
} from 'lucide-react';
import ManpowerAbout from './Services_Images/Manpower Recuitment.png';
import QueryForm from '../QueryForm/queryForm';
import './Servicesall.css';

const Manpower = () => {
  const [isBookingOpen, setIsBookingOpen] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [isQueryFormOpen, setIsQueryFormOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const statsRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const service = {
    icon: <Users className="w-16 h-16 text-indigo-400" />,
    title: "Enterprise Talent Solutions",
    description: "Comprehensive staffing and recruitment services for corporate clients and businesses worldwide.",
    price: "Customized Talent Acquisition",
    features: [
      "Strategic Workforce Planning",
      "Comprehensive Talent Screening",
      "Industry-Specific Recruitment",
      "Executive Search Services",
      "Permanent & Contract Staffing",
      "Global Talent Network",
      "Advanced Candidate Assessment",
      "Compliance & Background Verification"
    ]
  };

  const industries = [
    {
      icon: <Building2 className="w-12 h-12 text-blue-500" />,
      title: "Corporate Staffing",
      description: "End-to-end recruitment solutions"
    },
    {
      icon: <Briefcase className="w-12 h-12 text-purple-500" />,
      title: "Executive Recruitment",
      description: "Leadership and senior-level placements"
    },
    {
      icon: <Users className="w-12 h-12 text-green-500" />,
      title: "Contract Staffing",
      description: "Flexible workforce management"
    },
    {
      icon: <Calendar className="w-12 h-12 text-emerald-500" />,
      title: "Long-term Partnerships",
      description: "Ongoing talent acquisition support"
    }
  ];

  const features = [
    {
      icon: <Shield className="w-12 h-12 text-indigo-500" />,
      title: "Quality Screening",
      description: "Rigorous candidate evaluation"
    },
    {
      icon: <Settings className="w-12 h-12 text-purple-500" />,
      title: "Tailored Solutions",
      description: "Customized recruitment strategies"
    },
    {
      icon: <Timer className="w-12 h-12 text-blue-500" />,
      title: "Quick Deployment",
      description: "Rapid talent acquisition"
    },
    {
      icon: <MapPin className="w-12 h-12 text-green-500" />,
      title: "Multi-Industry Expertise",
      description: "Diverse sector knowledge"
    }
  ];

  // Use MUI's useMediaQuery to detect screen width
  const isMobile = useMediaQuery('(max-width:500px)');

  const videoSource = isMobile
    ? "/videos/HiringPlan1.mp4"
    : "/videos/Manpower1.mp4";

  const handleConnect = () => {
    console.log("handleConnect called");
    setIsQueryFormOpen(true);
    console.log("clicked");
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#FAFBFF]">
      {/* Hero Section */}
      <div className="relative">
        <div className="video-container relative">
          {!videoError ? (
            <video
              ref={videoRef}
              autoPlay
              muted
              loop
              playsInline
              className="w-full h-screen object-cover"
              onError={(e) => {
                console.error('Video error:', e);
                setVideoError(true);
              }}
            >
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={ManpowerAbout}
              alt="Resourcing services"
              className="w-full h-screen object-cover"
              style={{ opacity: '0.8' }}
            />
          )}

          {/* Content container with bottom-left aligned button */}
          <div className="connectUs absolute left-20 right-0 bottom-24 p-12">
            <button
              onClick={handleConnect}
              className="connect-button shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-105 flex items-center gap-2"
            >
              Connect with US
              <ArrowRight className="arrow-icon w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Animated Stats Section */}
      <div ref={statsRef} className="py-24 bg-gradient-to-b from-indigo-900 to-purple-900 relative overflow-hidden">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0zNiAxOGMzLjMxIDAgNiAyLjY5IDYgNnMtMi42OSA2LTYgNi02LTIuNjktNi02IDIuNjktNiA2LTZ6IiBzdHJva2U9InJnYmEoMjU1LDI1NSwyNTUsMC4xKSIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9nPjwvc3ZnPg==')] opacity-10" />

        <div className="container mx-auto px-8">
          <div className="grid md:grid-cols-4 gap-8">
            {[
              { number: "500+", label: "Corporate Clients", icon: <Building2 width={30} height={30} /> },
              { number: "10,000+", label: "Successful Placements", icon: <Award width={30} height={30} /> },
              { number: "98%", label: "Client Satisfaction", icon: <Shield width={30} height={30} /> },
              { number: "50+", label: "Industries Served", icon: <Gem width={30} height={30} /> }
            ].map((stat, index) => (
              <div
                key={index}
                className={`transform transition-all duration-700 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
                  }`}
                style={{ transitionDelay: `${index * 100}ms` }}
              >
                <div className="relative group">
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-500/30 to-indigo-500/30 rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500" />
                  <div className="relative p-8 rounded-2xl bg-white/10 backdrop-blur-xl border border-white/20 hover:border-white/30 transition-all duration-300 transform hover:scale-105">
                    <div className="flex flex-col items-center text-center">
                      <div className="text-amber-400 mb-4 transform group-hover:scale-110 transition-transform duration-300">
                        {stat.icon}
                      </div>
                      <div className="text-4xl font-bold text-white mb-2">{stat.number}</div>
                      <div className="text-white/80">{stat.label}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Industries We Serve */}
      <div className="container mx-auto px-8 py-24">
        <div className="text-center mb-20">
          <div className="inline-flex items-center gap-2 mb-4 px-6 py-3 bg-purple-100 rounded-full">
            <Users className="w-5 h-5 text-purple-600" />
            <span className="bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold">
              Services We Offer
            </span>
          </div>
          <h2 className="subHeadings font-bold mb-6 text-slate-900">
            Talent Acquisition Solutions
          </h2>
        </div>

        <div className="grid md:grid-cols-4 gap-8 mb-24">
          {industries.map((industry, index) => (
            <div key={index} className="group h-full">
              <div className="relative items-center p-8 rounded-2xl bg-white shadow-xl group-hover:shadow-2xl transition-all duration-500 h-full flex flex-col">
                <div className="mb-6 items-center">{industry.icon}</div>
                <h3 className="text-xl font-bold text-slate-900 mb-3">{industry.title}</h3>
                <p className="text-slate-600 flex-grow">{industry.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Service Card */}
        <div className="flex justify-center">
          <Card className="group relative overflow-hidden hover:shadow-2xl transition-all duration-500 border-0 max-w-2xl w-full">
            <div className="absolute inset-0 bg-gradient-to-br from-purple-50 to-indigo-50 opacity-50" />
            <div className="absolute inset-0 bg-gradient-to-br from-purple-500/5 to-indigo-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />

            <CardContent className="relative p-10">
              <div className="flex justify-center mb-10">
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-indigo-500/20 rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500" />
                  <div className="relative p-8 rounded-2xl bg-white shadow-xl group-hover:shadow-2xl transition-all duration-500">
                    {service.icon}
                  </div>
                </div>
              </div>

              <div className="text-center mb-10">
                <h3 className="text-3xl font-bold mb-4 text-slate-900">
                  {service.title}
                </h3>
                <p className="text-purple-600 font-semibold text-xl mb-4">
                  {service.price}
                </p>
                <p className="text-slate-600 leading-relaxed text-lg">
                  {service.description}
                </p>
              </div>

              <div className="grid md:grid-cols-2 gap-4 mb-10">
                {service.features.map((feature, idx) => (
                  <div key={idx} className="flex items-center gap-3 text-slate-700">
                    <CheckCircle2 className="w-5 h-5 text-purple-500 flex-shrink-0" />
                    <span>{feature}</span>
                  </div>
                ))}
              </div>

              <button onClick={handleConnect}
                className="w-full bg-gradient-to-r from-purple-500 to-indigo-600 hover:from-purple-600 hover:to-indigo-700 text-white py-4 rounded-xl shadow-lg group-hover:shadow-xl transition-all duration-300">
                Request Talent Consultation
              </button>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Features Grid */}
      <div className="bg-gradient-to-b from-purple-50 to-white py-24">
        <div className="container mx-auto px-8">
          <div className="text-center mb-20">
            <div className="inline-flex items-center gap-2 mb-4 px-6 py-3 bg-purple-100 rounded-full">
              <Crown className="w-5 h-5 text-amber-400" />
              <span className="bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold">
                SR Features
              </span>
            </div>
            <h2 className="subHeadings font-bold mb-6 text-slate-900">
              Connecting the Right Talent with Your Vision
            </h2>
          </div>

          <div className="grid md:grid-cols-4 gap-12">
            {features.map((feature, index) => (
              <div key={index} className="group text-center h-full">
                <div className="relative h-full">
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-indigo-500/20 rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500" />
                  <div className="relative flex flex-col items-center text-center p-6 rounded-2xl bg-white shadow-xl group-hover:shadow-2xl transition-all duration-500 h-full">
                    <div className="mb-3">{feature.icon}</div>
                    <h3 className="text-xl font-bold text-slate-900 mb-3">{feature.title}</h3>
                    <p className="text-slate-600">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Company Description Section */}
      <div className="py-14 px-5 w-full bg-[#0f313e] text-white">
        {/* Centered Title Section */}
        <div className="flex text-center justify-center mb-4">
          <div className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold transform transition-all duration-500 hover:scale-105">
            <h2 className="cardtitle font-bold transform transition-all duration-500 text-white">
            Why <span className="text-[#179da3]">SR Manpower Recruitment</span> is the best choice ?
            </h2>
          </div>
        </div>

        <div className="max-w-7xl mx-auto py-5 px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center gap-12">
          <div className="lg:w-2/6 transform transition-all duration-700 hover:scale-110">
            <div>
              <img
                src={ManpowerAbout}
                alt="Manpower Recruitment"
                className="w-full h-full object-cover aboutImageManpower"
              />
            </div>
          </div>

          <div className="flex-1 space-y-4">
            <p className="fontType text-white ">
              At SR Resourcing Pvt. Ltd., we specialize in delivering tailored manpower resourcing services to corporate
              clients across various industries. With our extensive experience in recruitment and staffing,
              we help businesses maintain operational productivity. Our recruitment process is designed to
              match talent with your specific business requirements, ensuring that you get the right people
              for the job every time.
            </p>
            <h5 className="text-[#179da3] text-center text-xl font-bold mt-7">Our Experience</h5>
            <p className="fontType text-white">
              Over the past 25 years, we have successfully placed thousands of professionals across diverse sectors.
              From entry-level positions to specialized senior roles, our workforce solutions have been trusted by
              some of the largest corporates in the country. We have fine-tuned our approach to understanding not
              only the skills but also the organizational culture of our clients, ensuring that our placements fit
              seamlessly into your business environment.
            </p>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="bg-gradient-to-b from-purple-50 to-white py-24">
        <div className="container mx-auto px-8 max-w-7xl">
          <div className="text-center mb-20">
            <div className="inline-flex items-center gap-2 mb-4 px-6 py-3 bg-purple-100 rounded-full">
              <Crown className="w-5 h-5 text-amber-400" />
              <span className="bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold">
                Client Testimonials
              </span>
            </div>
            <h2 className="subHeadings font-bold mb-6 text-slate-900">
              What Our Clients Say
            </h2>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                name: "Rajesh Kumar",
                role: "HR Director, Global Tech Company",
                quote: "Their talent acquisition strategies have transformed our recruitment process and helped us attract top-tier professionals."
              },
              {
                name: "Priya Sharma",
                role: "COO, Multinational Corporation",
                quote: "The precision and depth of their talent matching is unparalleled. They truly understand our organizational needs."
              },
              {
                name: "Mike Chen",
                role: "CEO, International Consulting Firm",
                quote: "Our partnership has been instrumental in building a world-class team across multiple challenging domains."
              }
            ].map((testimonial, index) => (
              <Card key={index} className="group hover:shadow-2xl transition-all duration-500 border-0 bg-gradient-to-br from-slate-50 to-white">
                <CardContent className="p-10">
                  <div className="flex mb-8 justify-center">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star key={star} className="w-6 h-6 text-amber-400 fill-current" />
                    ))}
                  </div>
                  <p className="text-slate-700 mb-8 text-lg leading-relaxed text-center">
                    "{testimonial.quote}"
                  </p>
                  <div className="flex items-center gap-4">
                    <div className="w-16 h-16 rounded-full bg-gradient-to-br from-emerald-200 to-blue-100 shadow-lg" />
                    <div>
                      <p className="font-bold text-slate-900">{testimonial.name}</p>
                      <p className="text-slate-600">{testimonial.role}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <QueryForm open={isQueryFormOpen} onClose={() => setIsQueryFormOpen(false)} />
    </div>
  );
};

export default Manpower;