import React, { useState } from 'react';
import MainHome from './AboutImage/AboutImage.png';
import { Crown, Users, Car, Home, Shield, Package, ArrowRight } from 'lucide-react';
import QueryForm from '../QueryForm/queryForm';

const About = () => {
  const services = [
    {
      icon: <Users className="w-12 h-12 text-purple-400" />,
      title: "Manpower Recruitment",
      description: "Comprehensive talent acquisition solutions across various industries, connecting skilled professionals with top employers."
    },
    {
      icon: <Car className="w-12 h-12 text-indigo-400" />,
      title: "Cab Rentals",
      description: "Reliable and comfortable transportation services for corporate and personal travel needs."
    },
    {
      icon: <Home className="w-12 h-12 text-violet-400" />,
      title: "Housekeeping",
      description: "Professional cleaning and maintenance services for offices, commercial spaces, and residential complexes."
    },
    {
      icon: <Shield className="w-12 h-12 text-blue-400" />,
      title: "Security Services",
      description: "Comprehensive security solutions including trained personnel, surveillance, and risk management."
    },
    {
      icon: <Package className="w-12 h-12 text-amber-400" />,
      title: "General Order Suppliers",
      description: "End-to-end supply chain and procurement services, ensuring timely and efficient resource management."
    }
  ];

  const team = [
    {
      name: "Saran Yadav",
      role: "Founder & Director",
      bio: "Sharan Yadav brings extensive experience in operations management, overseeing our cab rental, security services, and general order supply operations to ensure seamless service delivery."
    },
    {
      name: "Rohit Yadav",
      role: "Founder & Director",
      bio: "With over 15 years of experience in resource management and manpower recruitment, Rohit Yadav founded SR Resourcing Private Limited to provide comprehensive business services, including recruitment, housekeeping, and security solutions."
    },
    {
      name: "Satyansh Yadav",
      role: "Customer Relationship Manager",
      bio: "A seasoned professional in client services, Krishna Yadav is dedicated to providing tailored manpower and housekeeping services to meet the diverse needs of our clients."
    }
  ];

    const [isQueryFormOpen, setIsQueryFormOpen] = useState(false);

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-purple-50">
      {/* Hero Section */}


      <div className="min-h-screen grid">
           <div className="relative col-start-1 row-start-1">
               <img
                 src={MainHome}
                 alt="Resourcing services"
                 className="w-full h-screen object-cover"
               />
           </div>
   
           {/* <div className="col-start-1 row-start-1 bg-black/40 h-screen" /> */}
           <div className="absolute connectHome left-0 right-0 p-8">
             <button
               onClick={() => setIsQueryFormOpen(true)}
               className="connect-button"
             >
               Connect with US
               <ArrowRight className="arrow-icon" />
             </button>
           </div>
         </div>

      {/* Services Grid */}
      <div className="py-24 bg-white">
      <div className="container mx-auto px-8">
        <div className="grid md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="group relative">
              <div className="absolute inset-0 bg-gradient-to-br from-purple-500/5 to-indigo-500/5 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
              <div className="relative p-8 rounded-2xl bg-white shadow-lg group-hover:shadow-xl transition-all duration-500">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex items-center justify-center w-16 h-16 mb-6">
                    {service.icon}
                  </div>
                  <h3 className="text-xl font-bold text-slate-900 mb-4">
                    {service.title}
                  </h3>
                </div>
                <p className="text-slate-600">
                  {service.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
      {/* Mission Section */}
      <div className="py-24 bg-[#0f313e] text-center">
        <div className="container mx-auto px-8">
          <h2 className="text-4xl font-bold text-[#179da3] mb-8">Our Mission</h2>
          <p className="text-xl text-white/80 max-w-5xl mx-auto leading-relaxed">
            At SR Resourcing Private Limited, our mission is to deliver comprehensive and tailored
            solutions across manpower recruitment, cab rentals, housekeeping, security services,
            and general order supplies. We aim to foster long-term partnerships with our clients by
            providing exceptional service that meets their unique needs. Through our commitment to quality,
            efficiency, and integrity, we strive to empower businesses, enhance operational efficiency,
            and ensure reliable service delivery. Our focus is on creating a seamless,
            professional experience that drives success and builds trust with our clients.
          </p>
        </div>
      </div>

      {/* Team Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-slate-900 mb-4">Leadership Team</h2>
          </div>
          <div className="grid md:grid-cols-3 gap-12">
            {team.map((member, index) => (
              <div key={index} className="group h-full">
                <div className="relative h-full">
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-indigo-500/20 rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500" />
                  <div className="relative p-8 rounded-2xl bg-white shadow-xl group-hover:shadow-2xl transition-all duration-500 h-full flex flex-col items-center">
                    <div className="w-24 h-24 rounded-full bg-gradient-to-r from-purple-500 to-indigo-500 mb-6 flex items-center justify-center text-2xl font-bold text-white flex-shrink-0">
                      {member.name.charAt(0)}
                    </div>
                    <h3 className="text-xl font-bold text-slate-900 mb-2">{member.name}</h3>
                    <p className="text-purple-600 font-medium mb-4">{member.role}</p>
                    <p className="text-slate-600 text-center">{member.bio}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
       <QueryForm open={isQueryFormOpen} onClose={() => setIsQueryFormOpen(false)} />
    </div>
  );
};

export default About;