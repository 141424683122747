import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, ChevronDown, ChevronUp } from 'lucide-react';
import MainHome from './sr_consulting.jpg'
import './Nav.css';


const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState('');
  const [isServicesHovered, setIsServicesHovered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  

  const services = [
    { title: 'Manpower Recruitment', path: '/manPower' },
    { title: 'Cab Rentals', path: '/cabRental' },
    { title: 'Housekeeping', path: '/houseKeeping' },
    { title: 'Security', path: '/security' },
    { title: 'Other services', path: '/others' },
  ];

  return (
    <nav className={`navbar-appbar fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-white shadow-lg navbar-shadow-bottom' : 'bg-transparent'}`}>
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-2">
            <img src={MainHome} alt="SR Resourcing" className="w-8 rounded-3xl" />
            {/* <span className={`font-bold text-xl ${isScrolled ? 'bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text' : 'text-white'}`}> */}
            <span className={`font-bold text-xl ${isScrolled ? 'text-[#1a787d]' : 'text-white'}`}>
              SR Resourcing Pvt Ltd
            </span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex items-center space-x-8">
            {['HOME', 'ABOUT'].map((item) => (
              <Link
                key={item}
                to={item === 'HOME' ? '/' : `/${item.toLowerCase()}`}
                className={`font-medium hover:text-blue-600 transition-colors ${isScrolled ? 'text-gray-700' : 'text-white'
                  }`}
              >
                {item}
              </Link>
            ))}

            {/* Services Dropdown */}
            <div className="relative group"
              onMouseEnter={() => {
                setActiveDropdown('services');
                setIsServicesHovered(true);
              }}
              onMouseLeave={() => {
                setActiveDropdown('');
                setIsServicesHovered(false);
              }}>
              <button
                className={`flex items-center space-x-1 font-medium group-hover:text-blue-600 transition-colors ${isScrolled ? 'text-gray-700' : 'text-white'
                  }`}
              >
                <span>SERVICES</span>
                <div className="w-4 h-4 transition-transform duration-300 ease-in-out">
                  {isServicesHovered ? (
                    <ChevronUp className="w-4 h-4 transform transition-transform duration-300" />
                  ) : (
                    <ChevronDown className="w-4 h-4 transform transition-transform duration-300" />
                  )}
                </div>
              </button>

              <div className={`absolute left-0 mt-2 w-64 bg-white rounded-lg shadow-xl transition-all duration-300 ${activeDropdown === 'services'
                ? 'opacity-100 visible translate-y-0'
                : 'opacity-0 invisible -translate-y-2'
                }`}>
                <div className="p-2">
                  {services.map((service) => (
                    <Link
                      key={service.path}
                      to={service.path}
                      className="block px-4 py-3 text-sm text-gray-700 hover:bg-blue-50 hover:text-blue-600 rounded-lg transition-colors"
                    >
                      {service.title}
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            {['CAREERS', 'ContactUs'].map((item) => (
              <Link
                key={item}
                to={`/${item.toLowerCase()}`}
                className={`font-medium hover:text-blue-600 transition-colors ${isScrolled ? 'text-gray-700' : 'text-white'
                  }`}
              >
                {item}
              </Link>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="lg:hidden p-2 rounded-lg hover:bg-gray-100/10"
          >
            {isMobileMenuOpen ? (
              <X className={`w-6 h-6 ${isScrolled ? 'text-gray-900' : 'text-white'}`} />
            ) : (
              <Menu className={`w-6 h-6 ${isScrolled ? 'text-gray-900' : 'text-white'}`} />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="lg:hidden">
            <div className="py-2 bg-slate-50 space-y-1">
              {['HOME', 'ABOUT'].map((item) => (
                <Link
                  key={item}
                  to={item === 'HOME' ? '/' : `/${item.toLowerCase()}`}
                  className="block px-4 py-3  text-base font-medium text-gray-700 hover:bg-gray-100 rounded-lg"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {item}
                </Link>
              ))}

              {/* Mobile Services Dropdown */}
              <div className="relative">
                <button
                  onClick={() => setActiveDropdown(activeDropdown === 'mobile-services' ? '' : 'mobile-services')}
                  className="w-full px-4 py-3 text-base font-medium text-gray-700 hover:bg-gray-100 rounded-lg flex justify-between items-center"
                >
                  <span>SERVICES</span>
                  <div className="w-4 h-4 transition-transform duration-300 ease-in-out">
                    {activeDropdown === 'mobile-services' ? (
                      <ChevronUp className="w-4 h-4 transform transition-transform duration-300" />
                    ) : (
                      <ChevronDown className="w-4 h-4 transform transition-transform duration-300" />
                    )}
                  </div>
                </button>

                {activeDropdown === 'mobile-services' && (
                  <div className="pl-4">
                    {services.map((service) => (
                      <Link
                        key={service.path}
                        to={service.path}
                        className="block px-4 py-3 text-sm text-gray-600 hover:bg-gray-100 rounded-lg"
                        onClick={() => {
                          setActiveDropdown('');
                          setIsMobileMenuOpen(false);
                        }}
                      >
                        {service.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              {['CAREERS', 'ContactUs'].map((item) => (
                <Link
                  key={item}
                  to={`/${item.toLowerCase()}`}
                  className="block px-4 py-3 text-base font-medium text-gray-700 hover:bg-gray-100 rounded-lg"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {item}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;