import React from 'react';
import { 
  Building2, 
  Heart, 
  Trophy, 
  GraduationCap, 
  Users, 
  Briefcase,
  ArrowRight,
  CheckCircle2
} from 'lucide-react';
import { 
    Card, 
    CardContent, 
  } from '@mui/material';

const CareerPage = () => {
  const benefits = [
    {
      icon: <Heart className="w-8 h-8 text-pink-400" />,
      title: "Health & Wellness",
      perks: ["Comprehensive medical coverage", "Life insurance", "Mental health support"]
    },
    {
      icon: <Trophy className="w-8 h-8 text-amber-400" />,
      title: "Growth & Development",
      perks: ["Professional training", "Leadership programs", "Skill development workshops"]
    },
    {
      icon: <GraduationCap className="w-8 h-8 text-blue-400" />,
      title: "Learning Opportunities",
      perks: ["Education reimbursement", "Certification support", "Industry conferences"]
    },
    {
      icon: <Building2 className="w-8 h-8 text-purple-400" />,
      title: "Work-Life Balance",
      perks: ["Flexible work hours", "Paid time off", "Remote work options"]
    }
  ];

  const openings = [
    {
      title: "Senior Recruitment Specialist",
      department: "Human Resources",
      location: "Mumbai, India",
      type: "Full-time"
    },
    {
      title: "Operations Manager",
      department: "Operations",
      location: "Delhi, India",
      type: "Full-time"
    },
    {
      title: "Security Supervisor",
      department: "Security Services",
      location: "Bangalore, India",
      type: "Full-time"
    },
    {
      title: "Fleet Manager",
      department: "Cab Services",
      location: "Pune, India",
      type: "Full-time"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-purple-50">
      {/* Hero Section */}
      <div className="relative py-32 bg-gradient-to-b from-indigo-900 to-purple-900">
        <div className="container mx-auto px-8 relative z-10">
          <div className="text-center">
            <h1 className="text-5xl font-bold text-white mb-6">
              Join Our Team
            </h1>
            <p className="text-xl text-white/80 max-w-2xl mx-auto">
              Build Your Career with a Leading Resource Management Company
            </p>
          </div>
        </div>
      </div>

      {/* Why Join Us Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-slate-900 mb-4">Why Join Us?</h2>
            <p className="text-xl text-slate-600 max-w-3xl mx-auto">
              At SR Resourcing, we believe in nurturing talent and providing opportunities for growth in a dynamic work environment.
            </p>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <Card key={index} className="flex items-center justify-center group hover:shadow-xl transition-all duration-300">
                <CardContent className="p-6">
                  <div className="flex items-center justify-center mb-6">{benefit.icon}</div>
                  <h3 className="flex items-center justify-center text-xl font-bold text-slate-900 mb-4">
                    {benefit.title}
                  </h3>
                  <ul className="space-y-2">
                    {benefit.perks.map((perk, idx) => (
                      <li key={idx} className="flex items-center text-slate-600">
                        <CheckCircle2 className="w-4 h-4 text-green-500 mr-2" />
                        {perk}
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>

      {/* Current Openings */}
      <div className="py-24 bg-gradient-to-b from-indigo-900 to-purple-900">
        <div className="container mx-auto px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-white mb-4">Current Openings</h2>
            <p className="text-xl text-white/80 max-w-3xl mx-auto">
              Explore opportunities across our diverse business verticals
            </p>
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            {openings.map((job, index) => (
              <div key={index} className="group cursor-pointer">
                <div className="bg-white rounded-xl p-8 shadow-lg hover:shadow-2xl transition-all duration-300">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h3 className="text-xl font-bold text-slate-900 mb-2">{job.title}</h3>
                      <p className="text-purple-600 font-medium">{job.department}</p>
                    </div>
                    <Briefcase className="w-6 h-6 text-purple-400" />
                  </div>
                  <div className="flex items-center justify-between text-slate-600">
                    <div className="flex items-center space-x-4">
                      <span>{job.location}</span>
                      <span>•</span>
                      <span>{job.type}</span>
                    </div>
                    <ArrowRight className="w-5 h-5 text-purple-400 group-hover:translate-x-1 transition-transform" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Application Process */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-slate-900 mb-4">Our Hiring Process</h2>
            <p className="text-xl text-slate-600 max-w-3xl mx-auto">
              We've designed our recruitment process to be thorough yet efficient
            </p>
          </div>
          <div className="max-w-4xl mx-auto">
            <div className="relative">
              <div className="absolute left-8 top-0 bottom-0 w-0.5 bg-purple-200" />
              {[
                "Application Review",
                "Initial Phone Screen",
                "Technical/Role-Specific Interview",
                "Team Interview",
                "Offer & Onboarding"
              ].map((step, index) => (
                <div key={index} className="relative pl-20 pb-12 last:pb-0">
                  <div className="absolute left-0 w-16 h-16 rounded-full bg-gradient-to-br from-purple-500 to-indigo-500 flex items-center justify-center text-white font-bold">
                    {index + 1}
                  </div>
                  <div className="bg-white rounded-xl p-6 shadow-lg">
                    <h3 className="text-lg font-bold text-slate-900">{step}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerPage;