import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navbar from './component/NavBar/Navbar';
import Home from './component/Home/Home';

import Footer from './component/Footer/Footer';
import About from './component/About/About';
import Security from './component/Services/Security';
import ManPower from './component/Services/Manpower';
import HouseKeeping from './component/Services/HouseKeeping';
import CabRental from './component/Services/CabRentals';
import GeneralOrder from './component/Services/GeneralOrder';
import CareerPage from './component/Careers/Careers'
import ContactPage from './component/Contact/ContactUs';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollToTop from './component/ScrollTop';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/careers" element={<CareerPage />} />
        <Route path="/ContactUs" element={<ContactPage />} />
        <Route path="/security" element={<Security />} />
        <Route path="/manPower" element={<ManPower />} />
        <Route path="/houseKeeping" element={<HouseKeeping />} />
        <Route path="/cabRental" element={<CabRental />} />
        <Route path="/others" element={<GeneralOrder />} />
        {/* <Route path="/contact" element={<Contact />} /> */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
