import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, ChevronRight, Star } from 'lucide-react';
import QueryForm from '../QueryForm/queryForm';
import MainHome from './HomeImages/HomeCoverMobile.svg';
import Manpower from './HomeImages/Manpower Recruitment.jpeg';
import CabRental from './HomeImages/CabRental.svg';
import Housekeeping from './HomeImages/Housekeeping.jpeg';
import Security from './HomeImages/Security.jpeg';
import GeneralOrderSuppliers from './HomeImages/General-order-suppliers.jpeg';
import MahindraLogistic from './HomeImages/MAHINDRA_LOGISTICS_LOGO.jpg';
import MahindraMile from './HomeImages/Mahindra-Mile-Mobility.png';
import Steelman from './HomeImages/Steelman.png';
import BlueDart from './HomeImages/Blue_dart.jpeg';
import Quess from './HomeImages/Quess.svg';
import DenaBank from './HomeImages/Denabank.jpg';
import VodafoneIdea from './HomeImages/Logo_of_Vodafone_Idea.png';
import Reliance_Communications from './HomeImages/Reliance_Communications_Logo.svg';
import wipro_new from './HomeImages/wipro_new_logo.svg';
import HFCL_Blue from './HomeImages/HFCL-Blue-Logo.png';
import Nokia from './HomeImages/Nokia.svg'
import './Home.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from '@mui/material';

const HomePage = () => {
  const [isQueryFormOpen, setIsQueryFormOpen] = useState(false);

  const services = [
    {
      title: 'Manpower Recruitment',
      tagline: 'Connecting Talent with Opportunity',
      description: 'Our expertise ensures the right talent for every role, helping companies grow and individuals thrive.',
      icon: 'Users',
      path: '/Manpower',
      image: Manpower
    },
    {
      title: 'Cab Rentals',
      tagline: 'Reliable and Comfortable Transportation',
      description: 'Premium transportation solutions for every journey, ensuring comfort and safety.',
      icon: 'Car',
      path: '/cabRental',
      image: CabRental
    },
    {
      title: 'Housekeeping',
      tagline: 'Keeping Spaces Spotless and Organized',
      description: 'Professional cleaning services that transform your space with meticulous attention to detail.',
      icon: 'Home',
      path: '/houseKeeping',
      image: Housekeeping
    },
    {
      title: 'Security Services',
      tagline: 'Securing Networks. Securing Customer.',
      description: 'Comprehensive security solutions to protect what matters most.',
      icon: 'Shield',
      path: '/security',
      image: Security
    },
    {
      title: 'Other Services',
      tagline: 'Your Complete Supply Chain Solution',
      description: 'Streamlined procurement and supply chain management for all your needs.',
      icon: 'Package',
      path: '/generalOrder',
      image: GeneralOrderSuppliers
    }
  ];

  const clientReviews = [
    {
      name: 'Rajesh Sharma',
      company: 'Tata Consultancy Services',
      rating: 5,
      review: 'Exceptional service quality and professional approach. They truly understand our business needs.'
    },
    {
      name: 'Priya Desai',
      company: 'Reliance Jio',
      rating: 4,
      review: 'Reliable and efficient. Their manpower solutions have significantly improved our operational efficiency.'
    },
    {
      name: 'Amit Patel',
      company: 'Flipkart',
      rating: 5,
      review: 'Top-notch security and transportation services. A one-stop solution for all our requirements.'
    },
    {
      name: 'Sneha Iyer',
      company: 'HCL',
      rating: 5,
      review: 'Comprehensive and flexible services that adapt to our changing business needs. Highly recommend!'
    },
    {
      name: 'Neha Singh',
      company: 'Global Solutions',
      rating: 4,
      review: 'Impressive manpower recruitment. Their candidates consistently exceed our expectations.'
    },
    {
      name: 'Sanjay Mehta',
      company: 'Urban Logistics',
      rating: 5,
      review: 'Exceptional cab rental and security services. Their professionalism is unmatched.'
    }
  ];

  const [videoError, setVideoError] = useState(false);
  const videoRef = useRef(null);
  useEffect(() => {
    const playVideo = async () => {
      try {
        if (videoRef.current) {
          await videoRef.current.play();
        }
      } catch (error) {
        console.error('Video playback failed:', error);
        setVideoError(true);
      }
    };

    playVideo();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    pauseOnHover: false,
    arrows: false,
    variableWidth: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 1 }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      }
    ]
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 1 }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      }
    ],
    appendDots: (dots) => (
      <div style={{
        backgroundColor: 'transparent',
        borderRadius: '10px',
        padding: '30px 20px 0 20px',
        marginTop: '20px'
      }}>
        <ul style={{
          margin: '0px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {dots}
        </ul>
      </div>
    ),
    dotsClass: "slick-dots custom-dots"
  };

  // Use MUI's useMediaQuery to detect screen width
  const isMobile = useMediaQuery('(max-width:500px)');

  const videoSource = isMobile
    ? "/videos/HomeCoverMobile.mp4"
    : "/videos/HomeCover.mp4";

  return (
    <div className="fullscreen-container min-h-screen bg-[#FAFBFF]">
      {/* Hero Section */}
      <div className="min-h-screen grid">
        <div className="relative col-start-1 row-start-1">
          {!videoError ? (
            <video
              ref={videoRef}
              autoPlay
              muted
              loop
              playsInline
              className="w-full h-screen object-cover"
              onError={(e) => {
                console.error('Video error:', e);
                setVideoError(true);
              }}
            >
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={MainHome}
              alt="Resourcing services"
              className="w-full h-screen object-cover"
            />
          )}
        </div>

        {/* <div className="col-start-1 row-start-1 bg-black/40 h-screen" /> */}
        <div className="absolute connectHome left-0 right-0 p-8">
          <button
            onClick={() => setIsQueryFormOpen(true)}
            className="connect-button"
          >
            Connect with US
            <ArrowRight className="arrow-icon" />
          </button>
        </div>
      </div>

      <div className="bg-white pt-24 ">
        <div className="container flex flex-col items-center">
          <div className="inline-flex items-center gap-2 mb-4 px-6 py-3 bg-purple-100 rounded-full">
            <span className="bg-gradient-to-r text-xl from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold">
              Our Valuable Clients
            </span>
          </div>
          <div className="px-4 text-center mb-12">
            <p className="text-xl flex text-slate-600 max-w-2xl mx-auto">
              We are proud to serve industry leaders who trust our comprehensive services.
            </p>
          </div>
          <div className="container mx-auto mt-6 mb-6 bg-[linear-gradient(180deg,#ffffff_60.14%,rgba(135,206,235,0.36)_93.54%,rgba(135,206,235,0.32)_100%)] p-4">

            <Slider {...settings} className="client-logo-slider">
              {[
                { logo: MahindraLogistic, alt: 'Mahindra Logistics', className: "w-40 h-24 object-contain transition-all duration-300" },
                { logo: Steelman, alt: 'Steelman', className: 'w-32 h-20 object-contain transition-all duration-300' },
                { logo: VodafoneIdea, alt: 'Vodafone Idea', className: 'w-32 h-20 object-contain transition-all duration-300' },
                { logo: MahindraMile, alt: 'Blue Dart', className: 'w-32 h-20 object-contain transition-all duration-300' },
                { logo: BlueDart, alt: 'Blue Dart', className: 'w-48 h-24 object-contain transition-all duration-300' },
                { logo: Quess, alt: 'Quess', className: 'w-44 h-24 object-contain transition-all duration-300' },
              ].map(({ logo, alt, className }, index) => (
                <div key={index} className="px-9 flex items-center justify-center">
                  <img src={logo} alt={alt} className={`${className} opacity-100 hover:opacity-100`} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className="w-full">
        {services.map((service, index) => (
          <div
            key={service.title}
            className={`relative serviceCard w-full ${index % 2 === 0
              ? 'bg-[#f6f8ff]'
              : 'bg-[#0f313e]'
              }`}
          >
            <div
              className={`max-w-7xl mx-auto py-14 px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center gap-12 ${index % 2 === 1 ? 'md:flex-row-reverse' : 'md:flex-row'
                }`}
            >
              <div className="flex-1 transform transition-all duration-700 hover:scale-105">
                <div className="overflow-hidden rounded-2xl shadow-2xl">
                  <img
                    src={service.image}
                    alt={service.title}
                    className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                  />
                </div>
              </div>
              <div className="flex-1 space-y-4">
                <h2
                  className={`cardtitle font-bold transform transition-all duration-500  ${index % 2 === 0
                    ? 'text-slate-900'
                    : 'text-[#179da3]'
                    }`}
                >
                  {service.title}
                </h2>
                <div
                  className=
                  {`inline-flex items-center 
                    font-semibold rounded-full transform transition-all duration-500 hover:scale-105  
                    ${index % 2 === 0
                      ? 'text-slate-900'
                      : 'text-[#fff]'
                    }`}
                >
                  <i className={`text-4xl ${service.icon} text-current`} />
                  <span>{service.tagline}</span>
                </div>
                <p className={`text-xl leading-relaxed ${index % 2 === 0 ? 'text-slate-600' : 'text-[#fff]'}`}>
                  {service.description}
                </p>
                <a
                  href={service.path}
                  className={`inline-flex items-center gap-2 group transform transition-all duration-500 hover:translate-x-2 
                    ${index % 2 === 0
                    ? 'text-[#176f74]'
                    : 'text-[#179da3]'
                    }`}
                >
                  Explore Service
                  <ChevronRight className="w-5 h-5 mt-1 group-hover:translate-x-1 transition-transform" />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="py-14 px-5 w-full bg-[#0f313e] text-white">
        {/* Centered Title Section */}
        <div className="flex text-center justify-center mb-4">

          <div className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold transform transition-all duration-500 hover:scale-105">
            <h2 className="cardtitle font-bold transform transition-all duration-500 text-white">
              Welcome to <span className="text-[#179da3]">SR Resoursing Pvt. Ltd.</span>  – Your Trusted <span className="text-[#179da3]">Partner</span>
            </h2>
          </div>
        </div>

        <div className="max-w-7xl mx-auto py-5 px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center gap-12">
          <div className="flex-1 space-y-4">
            <div className="space-y-6">
              <p className="text-lg leading-relaxed">
                Established in 2000, SR Resoursing Pvt. Ltd. has been a pioneer in providing reliable and comprehensive resourcing services for corporate clients. With 25 years of experience, we specialize in offering tailored solutions across a wide range of sectors, including cab rentals, manpower, security services, housekeeping, and other customized resourcing services to meet the unique needs of businesses.
              </p>

              <p className="text-lg leading-relaxed">
                Our team, led by Mr. Rohit Yadav and Mrs. Saran Yadav, is dedicated to delivering excellence, ensuring the highest standards of professionalism and reliability. We understand the ever-changing needs of today's corporate world, and our services are designed to help your business operate smoothly and efficiently.
              </p>

              <p className="text-lg leading-relaxed">
                Whether you are looking for a fleet of vehicles for transportation, skilled manpower, top-notch security personnel, or trustworthy housekeeping services, SR Resoursing Pvt. Ltd. is your go-to partner. We take pride in our long-standing relationships with our clients, built on trust, quality, and commitment.
              </p>

              <p className="text-lg leading-relaxed">
                Explore our services today and experience the difference of working with a company that has been trusted by businesses for over two decades.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Client Reviews Section */}
      <div className="bg-gradient-to-b from-purple-50 to-white px-10 py-20">
        <div className="container mx-auto px-8">
          <div className="text-center mb-12">
            <div className="inline-flex items-center gap-2 mb-4 px-6 py-3 bg-purple-100 rounded-full">
              <span className="bg-gradient-to-r text-xl from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold">
                What Our Clients Say
              </span>
            </div>
            <p className="text-xl text-slate-600 max-w-2xl mx-auto">
              Hear from our satisfied clients about their experiences with our services.
            </p>
          </div>

          <style>{`
          .slick-dots li button:before {
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #CBD5E1;
            display: block;
            transition: background-color 0.3s ease;
          }
          .slick-dots li.slick-active button:before {
            background-color: #6D28D9; /* Purple color when active */
          }
        `}</style>

          <Slider {...sliderSettings}>
            {clientReviews.map((review, index) => (
              <div key={index} className="p-4">
                <div
                  className="bg-gradient-to-br from-purple-50 to-indigo-50 p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2"
                >
                  <div className="flex items-center mb-4">
                    <div className="flex text-yellow-500 mr-2">
                      {[...Array(review.rating)].map((_, i) => (
                        <Star key={i} className="w-5 h-5 fill-current" />
                      ))}
                    </div>
                    <span className="text-slate-600 ml-2">({review.rating}/5)</span>
                  </div>
                  <p className="text-slate-700 italic mb-4 h-24 overflow-hidden">"{review.review}"</p>
                  <div className="flex items-center">
                    <div className="w-12 h-12 bg-purple-200 rounded-full mr-4 flex items-center justify-center">
                      <span className="text-purple-800 font-bold">
                        {review.name.charAt(0)}
                      </span>
                    </div>
                    <div>
                      <h3 className="font-semibold text-slate-800">{review.name}</h3>
                      <p className="text-slate-600 text-sm">{review.company}</p>
                    </div>
                  </div>
                </div>
              </div>

            ))}
          </Slider>

        </div>
      </div>

      {/* {Our previous Clients} */}
      <div className="bg-white pt-24 ">
        <div className="container flex flex-col items-center">
          <div className="inline-flex items-center gap-2 mb-4 px-6 py-3 bg-purple-100 rounded-full">
            <span className="bg-gradient-to-r text-xl from-purple-600 to-indigo-600 text-transparent bg-clip-text font-semibold">
              Our Previous Clients
            </span>
          </div>
          <div className="text-center px-8 mb-12">
            <p className="text-xl text-slate-600 max-w-2xl mx-auto">
              Trusted by leading organizations across diverse industries, we've established a proven track record of delivering exceptional services.
            </p>
          </div>

          <div className="container mx-auto mt-6 mb-6 bg-[linear-gradient(180deg,#ffffff_60.14%,rgba(137,207,240,0.36)_93.54%,rgba(137,207,240,0.32)_100%)] p-4">
            <Slider {...settings} className="client-logo-slider">
              {[
                { logo: Reliance_Communications, alt: 'Mahindra Logistics', className: 'w-24 h-20 object-contain transition-all duration-300' },
                { logo: wipro_new, alt: 'Steelman', className: 'w-32 h-20 object-contain transition-all duration-300' },
                { logo: Nokia, alt: 'Vodafone Idea', className: 'w-32 h-20 object-contain transition-all duration-300' },
                { logo: HFCL_Blue, alt: 'Blue Dart', className: 'w-32 h-20 object-contain transition-all duration-300' },
                { logo: DenaBank, alt: 'DenaBank', className: 'w-32 h-20 object-contain transition-all duration-300' }
              ].map(({ logo, alt, className }, index) => (
                <div key={index} className="px-10 flex items-center justify-center">
                  <img src={logo} alt={alt} className={`${className} opacity-100 hover:opacity-100`} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <QueryForm open={isQueryFormOpen} onClose={() => setIsQueryFormOpen(false)} />
    </div>
  );
};

export default HomePage;